import * as React from 'react';
import Layout from '../components/Layout';
import Contact from '../components/Contact';
import ContactStyle from '../components/styles/Contact.styled';

const contact = (): JSX.Element => {
  return (
    <Layout theme="">
      <ContactStyle>
        <h1>Kancelaria Adwokacka Elwira Bębenek</h1>
        <Contact justifyContent="center" />
        <div className="aroundClock">
          <h3>Czynna: 24h/7d</h3>
          <p>
            <small>
              wyłącznie na zaproszenie skierowane przez Kancelarię do Klienta
            </small>
          </p>
        </div>
        <p>
          <span>Kancelaria oferuje obsługę zdalną przez </span>
          <a href="https://meet.google.com/" target="_blank" rel="noreferrer">
            meet.google.com
          </a>
          <span> jak też poprzez whatsapp.</span>
        </p>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.8rem',
            paddingTop: '1rem',
          }}
        >
          Kancelaria współpracuje z mediatorami, psychologami, detektywami,
          <br />
          biegłymi z zakresu szacowania nieruchomości, <br />
          biegłymi rewidentami, architektami, geodetami i księgowymi.
        </p>
      </ContactStyle>
    </Layout>
  );
};

export default contact;
