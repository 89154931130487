import styled from "styled-components";

const Contact = styled.div`
  text-align: center;
  min-height: 600px;
  padding: 1rem;
  @media (min-width: 992px) {
    padding: 0;
  }
  .aroundClock {
    margin: 1rem 0;
    h3 {
      margin-bottom: 0;
    }
  }
`;

export default Contact;
